import { AdminCompanyRemoveReason, DestType, NetworkAction, NetworkVehicleType, PacketAdminType } from "../packet-data-types";

import * as Buffers from 'buffer';

export class ServerPacket {
  buffer: Buffers.Buffer;
  cursor: number;

  packetLength: number;
  packetType: PacketAdminType;

  constructor(buffer: Buffers.Buffer) {
    this.buffer = buffer;
    this.packetLength = buffer.readUint16LE();
    this.packetType = buffer.readUint8(2);
    this.cursor = 3;
  }

  readBuffer(): Buffers.Buffer {
    let startIndex = this.cursor;
    let size = this.readUint16();
    this.cursor += size;
    return this.buffer.subarray(startIndex, this.cursor);
  }

  readString(): string {
    let startIndex = this.cursor;
    for (this.cursor; this.cursor < this.buffer.length && this.buffer.readUint8(this.cursor) != 0; this.cursor++) {
    }
    this.cursor++;
    return this.buffer.subarray(startIndex, this.cursor-1).toString();
  }

  readUint8(): number {
    this.cursor++;
    return this.buffer.readUint8(this.cursor-1);
  }

  readBoolean(): boolean {
    return this.readUint8() != 0;
  }

  readUint16(): number {
    this.cursor+=2;
    return this.buffer.readUint16LE(this.cursor-2);
  }

  readUint32(): number {
    this.cursor+=4;
    return this.buffer.readUint32LE(this.cursor-4);
  }

  readUint64(): bigint {
    this.cursor+=8;
    return this.buffer.readBigUint64LE(this.cursor-8);
  }
}

export class ServerProtocolPacket extends ServerPacket {

  protocolVersion: number;
  availableFrequencies: {[adminUpdateType: number]: number} = [];

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.protocolVersion = this.readUint8();

    while (this.readBoolean()) {
      let index = this.readUint16();
      let frequencies = this.readUint16();
      this.availableFrequencies[index] = frequencies;
    }
  }
}

export class ServerWelcomePacket extends ServerPacket {

  serverName: string;
  networkRevision: string;
  isDedicated: boolean;

  generationSeed: number;
  landscape: number;
  date: number;
  mapSizeX: number;
  mapSizeY: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.serverName = this.readString();
    this.networkRevision = this.readString();
    this.isDedicated = this.readBoolean();

    this.readString(); // Used to be map name, is now a nothing string
    this.generationSeed = this.readUint32();
    this.landscape = this.readUint8();
    this.date = this.readUint32();
    this.mapSizeX = this.readUint16();
    this.mapSizeY = this.readUint16()
  }
}

export class ServerNewGamePacket extends ServerPacket {
  constructor(buffer: Buffers.Buffer) {
    super(buffer);
  }
}

export class ServerShutdownPacket extends ServerPacket {
  constructor(buffer: Buffers.Buffer) {
    super(buffer);
  }
}

export class ServerDatePacket extends ServerPacket {

  date: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.date = this.readUint32();
  }
}

export class ServerClientJoinPacket extends ServerPacket {

  clientId: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.clientId = this.readUint32();
  }
}

export class ServerClientInfoPacket extends ServerPacket {

  clientId: number;
  clientHostname: string;
  clientName: string;
  joinDate: number;
  clientPlayas: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.clientId = this.readUint32();
    this.clientHostname = this.readString();
    this.clientName = this.readString();
    this.readUint8(); //Used to be language
    this.joinDate = this.readUint32();
    this.clientPlayas = this.readUint8();
  }
}

export class ServerClientUpdatePacket extends ServerPacket {

  clientId: number;
  clientName: string;
  clientPlayas: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.clientId = this.readUint32();
    this.clientName = this.readString();
    this.clientPlayas = this.readUint8();
  }
}

export class ServerClientQuitPacket extends ServerPacket {

  clientId: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.clientId = this.readUint32();
  }
}

export class ServerClientErrorPacket extends ServerPacket {

  clientId: number;
  error: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.clientId = this.readUint32();
    this.error = this.readUint8();
  }
}

export class ServerCompanyNewPacket extends ServerPacket {

  companyId: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.companyId = this.readUint8();
  }
}

export class ServerCompanyInfoPacket extends ServerPacket {

  companyId: number;
  companyName: string;
  presidentName: string;
  color: number;
  inauguratedYear: number;
  isAi: boolean;
  quartersBankrupt: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.companyId = this.readUint8();
    this.companyName = this.readString();
    this.presidentName = this.readString();
    this.color = this.readUint8();
    this.readBoolean(); // Random true value sent
    this.inauguratedYear = this.readUint32();
    this.isAi = this.readBoolean();
    this.quartersBankrupt = this.readUint8();
  }
}

export class ServerCompanyUpdatePacket extends ServerPacket {

  companyId: number;
  companyName: string;
  presidentName: string;
  color: number;
  quartersBankrupt: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.companyId = this.readUint8();
    this.companyName = this.readString();
    this.presidentName = this.readString();
    this.color = this.readUint8();
    this.readBoolean();
    this.quartersBankrupt = this.readUint8();
  }
}

export class ServerCompanyRemovePacket extends ServerPacket {

  companyId: number;
  removeReason: AdminCompanyRemoveReason;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.companyId = this.readUint8();
    this.removeReason = this.readUint8();
  }
}

export class ServerCompanyEconomyPacket extends ServerPacket {

  companyId: number;
  companyMoney: bigint;
  companyLoan: bigint;
  companyIncome: bigint;
  deliveredCargo: number;

  history: {
    companyValue: bigint,
    performanceHistory: number,
    deliveredCargo: number,
  }[];

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.companyId = this.readUint8();
    this.companyMoney = this.readUint64();
    this.companyLoan = this.readUint64();
    this.companyIncome = this.readUint64();
    this.deliveredCargo = this.readUint16();
    this.history = [];
    for (let i = 0; i < 2; i++) {
      this.history[i] = {
        companyValue: this.readUint64(),
        performanceHistory: this.readUint16(),
        deliveredCargo: this.readUint16(),
      };
    }
  }
}

export class ServerCompanyStatsPacket extends ServerPacket {

  companyId: number;
  vehicleData: {[vehicleType: number]: {
    vehicles: number,
    stations: number,
  }};

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.companyId = this.readUint8();
    this.vehicleData = {};

    for (let i = 0; i < NetworkVehicleType.NETWORK_VEH_END; i++) {
      this.vehicleData[i] = {
        vehicles: this.readUint16(),
        stations: 0
      }
    }

    for (let i = 0; i < NetworkVehicleType.NETWORK_VEH_END; i++) {
      this.vehicleData[i].stations = this.readUint16();
    }
  }
}

export class ServerChatPacket extends ServerPacket {

  action: NetworkAction;
  destType: DestType;
  originClientId: number;
  message: string;
  data: bigint; // Arbitrary Extra Data

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.action = this.readUint8();
    this.destType = this.readUint8();
    this.originClientId = this.readUint32();
    this.message = this.readString();
    this.data = this.readUint64();
  }
}

export class ServerRCONEndPacket extends ServerPacket {

  command: string;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.command = this.readString();
  }
}

export class ServerRCONPacket extends ServerPacket {

  color: number;
  result: string;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.color = this.readUint16();
    this.result = this.readString();
  }
}

export class ServerConsolePacket extends ServerPacket {

  origin: string;
  message: string;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.origin = this.readString();
    this.message = this.readString();
  }
}

export class ServerGameScriptPacket extends ServerPacket {

  json: JSON;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.json = JSON.parse(this.readString());
  }
}

export class ServerPongPacket extends ServerPacket {

  d1: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.d1 = this.readUint32();
  }
}

/*
 *  It is best to handle these by the indices of the
 *  `commands` field. It is possible that the server will
 *  send multiple packets at once in response to one request
 *  because of the MTU limit.
 */
export class ServerCmdNamesPacket extends ServerPacket {

  commands: {[index: number]: string};

  constructor(buffer: Buffers.Buffer) {
    super(buffer);

    this.commands = {};
    while (this.readBoolean()) {
      let index = this.readUint16();
      this.commands[index] = this.readString();
    }
  }
}

export class ServerCmdLoggingPacket extends ServerPacket {

  clientId: number;
  companyId: number;
  command: number;
  commandPacketBuffer: Buffers.Buffer;
  commandFrame: number;

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    this.clientId = this.readUint32();
    this.companyId = this.readUint8();
    this.command = this.readUint16();
    this.commandPacketBuffer = this.readBuffer();
    this.commandFrame = this.readUint32();
  }
}

export class ServerAuthRequestPacket extends ServerPacket {

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    throw new Error("Unhandled Packet Type: Server Auth Request");
  }
}

export class ServerEnableEncryptionPacket extends ServerPacket {

  constructor(buffer: Buffers.Buffer) {
    super(buffer);
    throw new Error("Unhandled Packet Type: Server Enable Encryption");
  }
}
