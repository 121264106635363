<p>Container Name:</p>
<input type="text" name="containerName" placeholder="Container/Save name..." [formControl]="nameControl">
<p>Port:</p>
<input type="number" name="port" [formControl]="portControl" />
<br>
<button (click)="createConfigEntry()">Add Config</button>
<table>
  <tr>
    <td>Section</td>
    <td>Key</td>
    <td>Value</td>
  </tr>
  <tr *ngFor="let configOption of configOptions">
    <td>
      <select [(ngModel)]="configOption.section"> <!--Ignore error-->
        <option *ngFor="let section of sections" [ngValue]="section">{{section}}</option>
      </select>
    </td>
    <td><input type="text" [(ngModel)]="configOption.key"></td>
    <td><input type="text" [(ngModel)]="configOption.value"></td>
  </tr>
</table>
<sub>You can reference the openttd wiki for the configuration options.</sub>
<br>
<button (click)="createContainer()">Create Container</button>
