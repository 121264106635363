import { PacketAdminType } from "../packet-data-types";
import { ServerChatPacket, ServerClientErrorPacket, ServerClientInfoPacket, ServerClientJoinPacket, ServerClientQuitPacket, ServerClientUpdatePacket, ServerCmdLoggingPacket, ServerCmdNamesPacket, ServerCompanyEconomyPacket, ServerCompanyInfoPacket, ServerCompanyNewPacket, ServerCompanyRemovePacket, ServerCompanyStatsPacket, ServerCompanyUpdatePacket, ServerConsolePacket, ServerDatePacket, ServerGameScriptPacket, ServerNewGamePacket, ServerPacket, ServerPongPacket, ServerProtocolPacket, ServerRCONEndPacket, ServerRCONPacket, ServerShutdownPacket, ServerWelcomePacket } from "./server-packet";

import * as Buffers from 'buffer';

type DummyServerPacket = { new(buffer: Buffers.Buffer): ServerPacket };
export let packetConstructorMap: {[packetAdminType: number]: DummyServerPacket} = {};

export function processPacket(buffer: Buffers.Buffer): ServerPacket {
  let packetType: PacketAdminType = buffer.readUint8(2);
  if (typeof(packetConstructorMap[packetType]) != "function") {
    return new ServerPacket(buffer);
  }
  return new packetConstructorMap[packetType](buffer);
}

function addPacket(packetAdminType: number, packetClass: DummyServerPacket) {
  packetConstructorMap[packetAdminType] = packetClass;
}

addPacket(PacketAdminType.ADMIN_PACKET_SERVER_PROTOCOL, ServerProtocolPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_WELCOME, ServerWelcomePacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_NEWGAME, ServerNewGamePacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_SHUTDOWN, ServerShutdownPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_DATE, ServerDatePacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CLIENT_JOIN, ServerClientJoinPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CLIENT_INFO, ServerClientInfoPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CLIENT_UPDATE, ServerClientUpdatePacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CLIENT_QUIT, ServerClientQuitPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CLIENT_ERROR, ServerClientErrorPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_COMPANY_NEW, ServerCompanyNewPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_COMPANY_INFO, ServerCompanyInfoPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_COMPANY_UPDATE, ServerCompanyUpdatePacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_COMPANY_REMOVE, ServerCompanyRemovePacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_COMPANY_ECONOMY, ServerCompanyEconomyPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_COMPANY_STATS, ServerCompanyStatsPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CHAT, ServerChatPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_RCON, ServerRCONPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_RCON_END, ServerRCONEndPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CONSOLE, ServerConsolePacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CMD_NAMES, ServerCmdNamesPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_CMD_LOGGING, ServerCmdLoggingPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_GAMESCRIPT, ServerGameScriptPacket);
addPacket(PacketAdminType.ADMIN_PACKET_SERVER_PONG, ServerPongPacket);
