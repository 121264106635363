<button routerLink="/create">Create OpenTTD Instance</button>
<table>
  <tr>
    <td>ID</td>
    <td>Name</td>
    <td>State</td>
    <td>Status</td>
    <td>Ports</td>
    <td>Start</td>
    <td>Stop</td>
    <td>Remove</td>
    <td>Console</td>
  </tr>
  <tr *ngFor="let container of containers">
    <td>{{container.Id.substring(0,12)}}</td>
    <td>{{container.Names}}</td>
    <td>{{container.State}}</td>
    <td>{{container.Status}}</td>
    <td><p class="port" *ngFor="let port of container.Ports">{{port.IP}}:{{port.PublicPort}}/{{port.PrivatePort}}/{{port.Type}}</p></td>
    <td><button *ngIf="container.State != 'running'" (click)='startContainer(container)'>Start</button></td>
    <td><button *ngIf="container.State == 'running'" (click)='stopContainer(container)'>Stop</button></td>
    <td><button (click)='removeContainer(container)'>Remove</button></td>
    <td><button (click)='openConsole(container)'>Open Console</button></td>
  </tr>
</table>
