import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminPollPacket, AdminRconPacket, AdminUpdateFrequencyPacket } from 'src/admin/data/admin/admin-packet';
import { AdminUpdateFrequency, AdminUpdateType, PacketAdminType } from 'src/admin/data/packet-data-types';
import { processPacket } from 'src/admin/data/server/packet-constructor';
import { ServerChatPacket, ServerClientInfoPacket, ServerConsolePacket, ServerPacket, ServerWelcomePacket } from 'src/admin/data/server/server-packet';
import { AdminConsoleService } from 'src/app/services/admin-console.service';
import * as Buffers from 'buffer';

@Component({
  selector: 'app-admin-console',
  templateUrl: './admin-console.component.html',
  styleUrls: ['./admin-console.component.css']
})
export class AdminConsoleComponent implements OnInit {

  users: {[id: number]: ServerClientInfoPacket} = {};
  containerId: string = "";
  messages: string[] = [];

  commandInput = '';

  constructor(
    private router: Router,
    private adminConsole: AdminConsoleService,
  ){}

  ngOnInit() {
    this.adminConsole.consoleReady.subscribe(() => {
      let updateFrequencies: {[type: number]: number} = {};
      updateFrequencies[AdminUpdateType.ADMIN_UPDATE_CLIENT_INFO] = AdminUpdateFrequency.ADMIN_FREQUENCY_AUTOMATIC;
      updateFrequencies[AdminUpdateType.ADMIN_UPDATE_CHAT] = AdminUpdateFrequency.ADMIN_FREQUENCY_AUTOMATIC;
      updateFrequencies[AdminUpdateType.ADMIN_UPDATE_CONSOLE] = AdminUpdateFrequency.ADMIN_FREQUENCY_AUTOMATIC;

      let keys: number[] = Object.keys(updateFrequencies).map(type => parseInt(type));

      for (let updateType of keys) {
        this.adminConsole.sendPacket(new AdminUpdateFrequencyPacket(updateType, updateFrequencies[updateType]));
      }

      this.adminConsole.sendPacket(new AdminPollPacket(AdminUpdateType.ADMIN_UPDATE_CLIENT_INFO, 0xFFFFFFF));
    });
    this.adminConsole.serverPacket.subscribe((serverPacket: ServerPacket) => {
      serverPacket = processPacket(Buffers.Buffer.from(serverPacket.buffer));
      console.log('processing server packet of type ', PacketAdminType[serverPacket.packetType], ', ', serverPacket);
      if (serverPacket instanceof ServerChatPacket) {
        let packet: ServerChatPacket = serverPacket;
        this.messages.push('[chat] (' + this.users[packet.originClientId].clientName + '): ' + packet.message);
      } else if (serverPacket instanceof ServerConsolePacket) {
        let packet: ServerConsolePacket = serverPacket;
        this.messages.push('[console] ' + packet.message);
      } else if (serverPacket instanceof ServerClientInfoPacket) {
        let packet: ServerClientInfoPacket = serverPacket;
        this.users[packet.clientId] = packet;
      } else if (serverPacket instanceof ServerWelcomePacket) {
        let packet: ServerWelcomePacket = serverPacket;
        this.messages.push('Connected to ', packet.serverName);
      }
    });

    this.adminConsole.startConnection();
    this.adminConsole.requestConsole(this.containerId);
  }

  runCommand() {
    this.adminConsole.sendPacket(new AdminRconPacket(this.commandInput));
    this.commandInput = '';
  }

  testConnection() {
    console.log('Starting connection');
    this.containerId = this.router.lastSuccessfulNavigation!.extras.state!['cid']!;
    this.adminConsole.startConnection();
    this.adminConsole.requestConsole(this.containerId);
  }

}
