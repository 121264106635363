import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ContainerInspectInfo } from 'dockerode';
import { DockerizerApiService } from 'src/app/services/dockerizer-api.service';

@Component({
  selector: 'app-createinstance',
  templateUrl: './createinstance.component.html',
  styleUrls: ['./createinstance.component.css']
})
export class CreateinstanceComponent {

  nameControl = new FormControl<string>('');
  portControl = new FormControl<number>(3979);

  // TODO LIST:
  // Config settings
  // NewGRF settings
  configOptions: {section: string, key: string, value: string}[] = [];
  sections = [
    "misc",
    "difficulty",
    "game_creation",
    "vehicle",
    "construction",
    "station",
    "economy",
    "linkgraph",
    "pf",
    "order",
    "script",
    "ai",
    "locale",
    "gui",
    "sound",
    "music",
    "news_display",
    "network",
    "currency",
    "company",
  ];

  constructor(
    private dockerizer: DockerizerApiService,
  ){}

  createContainer() {
    this.dockerizer.createContainer({
      name: this.nameControl.value!,
      port: this.portControl.value!,
      config: this.compileConfig()
    }).subscribe((container: ContainerInspectInfo) => {
        console.log(container);
      });
  }

  compileConfig(): object {
    let config: {[section: string]: {[key: string]: string}} = {};
    this.configOptions.forEach(({section, key, value}) => {
      if (typeof config[section] != 'object') {
        config[section] = {};
      }
      config[section][key] = value;
    });
    return config;
  }
  createConfigEntry() {
    this.configOptions.push({section: "network", key: "", value: ""});
  }
}
