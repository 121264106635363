import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerInfo, ContainerInspectInfo } from 'dockerode';
import { Observable } from 'rxjs';
import { InstanceOptions } from 'src/models/instancing';

@Injectable({
  providedIn: 'root'
})
export class DockerizerApiService {

  API_ENDPOINT: string = "/api/v1/"

  postHeaders = {
    "Content-type": "application/json",
  }
  postOptions = {
    headers: this.postHeaders,
  }

  constructor(
    private http: HttpClient
  ) { }

  getContainers(): Observable<ContainerInfo[]> {
    return this.http.get<ContainerInfo[]>(this.API_ENDPOINT+"containers");
  }

  getContainerIds(): Observable<string[]> {
    return this.http.get<string[]>(this.API_ENDPOINT+"containerIds");
  }

  getContainerNames(): Observable<string[]> {
    return this.http.get<string[]>(this.API_ENDPOINT+"containerNames");
  }

  createContainer(instanceOptions: InstanceOptions): Observable<ContainerInspectInfo> {
    return this.http.post<ContainerInspectInfo>(this.API_ENDPOINT+"container", instanceOptions, this.postOptions);
  }

  startContainer(instanceId: string): Observable<any> {
    return this.http.post<any>(this.API_ENDPOINT+"startContainer", {instanceId}, this.postOptions);
  }

  stopContainer(instanceId: string): Observable<any> {
    return this.http.post<any>(this.API_ENDPOINT+"stopContainer", {instanceId}, this.postOptions);
  }

  removeContainer(instanceId: string): Observable<any> {
    return this.http.delete<any>(this.API_ENDPOINT+"container", {params: {instanceId}});
  }
}
