// DISABLE ASAP, COMPILER BUG YELLS ABOUT `protocolData` TYPES WHEN IT SHOULDNT BE
import { AdminUpdateFrequency, AdminUpdateType, AtomicPacketSegment, AtomicPacketSegmentType, DestType, NetworkAction, PacketAdminType, PacketSegment } from "../packet-data-types";

import * as Buffers from 'buffer';

export abstract class AdminPacket {
  packetType: PacketAdminType;
  protocolizedData: PacketSegment[];
  packetBuffer: Buffers.Buffer;
  constructor(packetType: PacketAdminType, protocolizedData: PacketSegment[]) {
    let packetBuffer = Buffers.Buffer.alloc(3);
    packetBuffer.writeUint8(packetType, 2);
    function writeAtomicPacketSegment(dataSegment: AtomicPacketSegment) {
      let buffer: Buffers.Buffer;
      switch (dataSegment.dataType) {
	case AtomicPacketSegmentType.STRING:
	  buffer = Buffers.Buffer.alloc(dataSegment.data.length+1);
	  buffer.write(dataSegment.data);
	  break;
	case AtomicPacketSegmentType.UINT8:
	  buffer = Buffers.Buffer.alloc(1);
	  buffer.writeUint8(dataSegment.data);
	  break;
	case AtomicPacketSegmentType.UINT16:
	  buffer = Buffers.Buffer.alloc(2);
	  buffer.writeUint16LE(dataSegment.data);
	  break;
	case AtomicPacketSegmentType.UINT32:
	  buffer = Buffers.Buffer.alloc(4);
	  buffer.writeUint32LE(dataSegment.data);
	  break;
	default:
	  buffer = Buffers.Buffer.alloc(0);
      }
      packetBuffer = Buffers.Buffer.concat([packetBuffer, buffer]);
    }
    for (let protocolData of protocolizedData) {
      if (protocolData.isMultiple) {
	for (let subSegment of protocolData.data) {
	  writeAtomicPacketSegment(subSegment);
	}
      } else if (protocolData.isMultiple == false) { // Written like this because typescript type checking yells otherwise
	writeAtomicPacketSegment(protocolData.data);
      }
    }
    packetBuffer.writeUint16LE(packetBuffer.byteLength);
    this.packetBuffer = packetBuffer;
    this.packetType = packetType;
    this.protocolizedData = protocolizedData;
  }

}

export class AdminJoinPacket extends AdminPacket {
  password: string;
  admin_name: string;
  admin_version: string;
  constructor(password: string, admin_name: string, admin_version: string) {
    super(PacketAdminType.ADMIN_PACKET_ADMIN_JOIN, [{
      isMultiple: false,
      data: {
	dataType: AtomicPacketSegmentType.STRING,
	data: password
      }
    }, {
      isMultiple: false,
      data: {
	  dataType: AtomicPacketSegmentType.STRING,
	  data: admin_name
      }
    }, {
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.STRING,
	  data: admin_version
	}
    }]);

    this.password = password;
    this.admin_name = admin_name;
    this.admin_version = admin_version;
  }

}

export class AdminQuitPacket extends AdminPacket {
  constructor() {
    super(PacketAdminType.ADMIN_PACKET_ADMIN_QUIT, []);
  }
}

export class AdminUpdateFrequencyPacket extends AdminPacket {
  updateType: AdminUpdateType;
  updateFrequency: AdminUpdateFrequency;

  constructor(updateType: AdminUpdateType, updateFrequency: AdminUpdateFrequency) {
    super(PacketAdminType.ADMIN_PACKET_ADMIN_UPDATE_FREQUENCY, [{
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.UINT16,
	  data: updateType,
	}
      },
      {
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.UINT16,
	  data: updateFrequency
	}
      }
    ]);
    this.updateType = updateType;
    this.updateFrequency = updateFrequency;
  }
}

export class AdminPollPacket extends AdminPacket {
  updateType: AdminUpdateType;
  // Depending on the update type, this data is used to give context
  // to what we are looking for
  context: number;

  constructor(updateType: AdminUpdateType, context: number = 0) {
    super(PacketAdminType.ADMIN_PACKET_ADMIN_POLL, [
      {
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.UINT8,
	  data: updateType,
	}
      },
      {
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.UINT32,
	  data: context,
	}
      }
    ]);
    this.updateType = updateType;
    this.context = context;
  }
}

export class AdminChatPacket extends AdminPacket {
  action: NetworkAction;
  destType: DestType;
  destination: number;
  message: string;

  constructor(action: NetworkAction, destType: DestType, destination: number, message: string) {
    super(PacketAdminType.ADMIN_PACKET_ADMIN_CHAT, [
      {
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.UINT8,
	  data: action,
	}
      },
      {
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.UINT8,
	  data: destType,
	}
      },
      {
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.UINT32,
	  data: destination,
	}
      },
      {
	isMultiple: false,
	data: {
	  dataType: AtomicPacketSegmentType.STRING,
	  data: message,
	}
      }
    ]);
    this.action = action;
    this.destType = destType;
    this.destination = destination;
    this.message = message;
  }
}

export class AdminRconPacket extends AdminPacket {
  command: string;

  constructor(command: string) {
    super(PacketAdminType.ADMIN_PACKET_ADMIN_RCON, [
      {
        isMultiple: false,
        data: {
          dataType: AtomicPacketSegmentType.STRING,
          data: command,
        }
      }
    ]);
    this.command = command;
  }
}

