import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Container, ContainerInfo, NetworkInspectInfo } from 'dockerode';
import { DockerizerApiService } from 'src/app/services/dockerizer-api.service';
@Component({
  selector: 'app-adminpanel',
  templateUrl: './adminpanel.component.html',
  styleUrls: ['./adminpanel.component.css']
})
export class AdminpanelComponent implements OnInit, OnDestroy {

  containers: ContainerInfo[] = [];
  network: NetworkInspectInfo | undefined;
  containerRefreshInterval: NodeJS.Timeout | undefined;

  constructor(
    private dockerizer: DockerizerApiService,
    private router: Router,
  ){}

  ngOnInit() {
    this.getContainers();
    this.containerRefreshInterval = setInterval(this.getContainers.bind(this), 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.containerRefreshInterval);
  }

  getContainers() {
    this.dockerizer.getContainers().subscribe(this.onGetContainers.bind(this));
  }

  onGetContainers(containers: ContainerInfo[]) {
    this.containers = containers;
    this.containers.forEach((container: ContainerInfo) => {
      container.Ports = container.Ports.sort((a,b)=>{return (a.IP+':'+a.PublicPort+'/'+a.PrivatePort+'/'+a.Type)
        .localeCompare(b.IP+':'+b.PublicPort+'/'+b.PrivatePort+'/'+b.Type)});
    });
  }

  startContainer(container: ContainerInfo) {
    this.dockerizer.startContainer(container.Id).subscribe(this.getContainers.bind(this));
  }

  stopContainer(container: ContainerInfo) {
    this.dockerizer.stopContainer(container.Id).subscribe(this.getContainers.bind(this));
  }

  removeContainer(container: ContainerInfo) {
    this.dockerizer.removeContainer(container.Id).subscribe(this.getContainers.bind(this));
  }


  openConsole(container: ContainerInfo) {
    this.router.navigateByUrl('/console', {state: {cid: container.Id}});
  }
}
