import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminpanelComponent } from './components/adminpanel/adminpanel.component';
import { CreateinstanceComponent } from './components/createinstance/createinstance.component';
import { AdminConsoleComponent } from './components/admin-console/admin-console.component';

const routes: Routes = [
  {
    path: "",
    component: AdminpanelComponent,
  },
  {
    path: "create",
    component: CreateinstanceComponent,
  },
  {
    path: 'console',
    component: AdminConsoleComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
