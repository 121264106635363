import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import { AdminPacket } from 'src/admin/data/admin/admin-packet';
import { ServerPacket } from 'src/admin/data/server/server-packet';

import * as SocketIOParser from '@kim5257/socket.io-parser-bigint';

@Injectable({
  providedIn: 'root'
})
export class AdminConsoleService implements OnDestroy {

  sock: Socket;
  ready: boolean = false;

  public readonly consoleReady: EventEmitter<void> = new EventEmitter();
  public readonly consoleFailed: EventEmitter<void> = new EventEmitter();
  public readonly disconnect: EventEmitter<void> = new EventEmitter();
  public readonly serverPacket: EventEmitter<ServerPacket> = new EventEmitter();


  constructor(
  ) {
    this.sock = io("http://homeserver:3003/", {
      autoConnect: false,
      transports: ['websocket', 'polling'],
      path: '/consock',
      parser: SocketIOParser,
    });

    this.sock.on('consoleFailed', () => {
      this.stopConnection();
      this.consoleFailed.emit();
    });

    this.sock.on('consoleReady', () => {
      this.ready = true;
      this.consoleReady.emit();
    });

    this.sock.on('disconnect', (disconnectReason: string) => {
      console.log('disconnect:', disconnectReason);
      this.disconnect.emit();
    });

    this.sock.on('serverPacket', ({serverPacket: packet}) => {
      this.serverPacket.emit(packet);
    });
  }

  startConnection() {
    this.sock.connect();
  }

  requestConsole(containerId: string) {
    this.sock.emit('requestConsole', {containerId});
  }

  sendPacket(adminPacket: AdminPacket) {
    this.sock.emit('adminPacket', {adminPacket});
  }

  stopConnection() {
    this.ready = false;
    this.sock.disconnect();
  }

  ngOnDestroy(): void {
    this.stopConnection();
  }
}
